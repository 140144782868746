body {
  font-family: GeogrotesqueSlab-Rg;
  color: #5a5b5d;
  background-color: #fff;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: CervoNeue-BoldNeue !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-transform: uppercase !important;
}
a {
  text-decoration: none !important;
}
input[name="link"] {
  display: none;
}
.headline {
  font-family: GeogrotesqueSlab-Rg;
  font-size: 1.8rem;
}
.headline-block {
  background-color: #d1cec5 !important;
  color: #fff;
  font-size: 1.5em;
}
/* color definitions */
.color-acker-back {
  background-color: #fff !important;
}
.color-acker-primary {
  background-color: #4e2f2b !important;
}
.color-acker-secondary {
  background-color: #5a5b5d !important;
}
.color-acker-bauer {
  background-color: #728143;
}
.color-acker-schreiner {
  background-color: #c0ae87;
}
.color-acker-toepfer {
  background-color: #5b4f40;
}
.color-acker-weber {
  background-color: #c2c0bd;
}
.color-acker-gerber {
  background-color: #4c2c2b;
}
.color-acker-papierer {
  background-color: #aaa891;
}
.font-color-schreiner {
  color: #c0ae87;
}
.font-color-alternative {
  color: #5a5b5d;
}
.font-color-primary {
  color: #4e2f2b;
}
#announceBox p {
  color: #c0ae87;
}
#announceBoxAlternative p {
  color: #5a5b5d;
}
.small-box {
  width: 50%;
}
@media (max-width: 425px) {
  .small-box {
    width: 100%;
  }
}
/* headlines and text definitions */
.catTitle {
  font-family: CervoNeue-BoldNeue !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-size: 2.8rem !important;
}
.mediumTitle {
  font-weight: normal;
  font-style: normal;
  font-size: 1.6rem !important;
}
.catTitleHomeTxtBlk {
  font-family: CervoNeue-BoldNeue !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-size: 3.2rem !important;
  color: #ecede9 !important;
  line-height: 1 !important;
}
.regTxt {
  font-size: 1.2rem;
}
.biggerTxt,
ul > li {
  font-size: 1.4rem;
}
.cervo {
  font-family: CervoNeue-BoldNeue !important;
  font-weight: normal !important;
  font-style: normal !important;
}
.txtBlkInnerCont {
  color: #ecede9;
}
.main_image img,
.maxImgWidth img {
  max-width: 100%;
}
/* list definitions */
.fa-ul-pagelist {
  list-style: none !important;
  display: inline-block !important;
  text-align: left !important;
}
li {
  list-style-type: none !important;
}
.fa-ul-pagelist li:before {
  font-family: "Font Awesome 5 Free";
  content: "\f192";
  padding-right: 10px;
}
/* booking expandable and modal booking form */
#fixed-sidebar {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 2;
}
.sideBarElement {
  cursor: pointer;
  width: auto;
  height: auto;
  padding: 1rem;
  box-shadow: 5px 5px 5px -5px #000;
  background-color: #f7a941;
}
.sideBarElement:hover {
  background-color: #d29137;
}
/* booking expandable and modal booking form */
#fixed-bookingBtn {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 2;
  cursor: pointer;
  width: auto;
  height: auto;
  background-color: #f7a941;
  color: #fff;
  padding: 1rem;
  box-shadow: 5px 5px 5px -5px #000;
}
#fixed-bookingBtn:hover {
  background-color: #f7a941 !important;
}
.modal-dialog {
  max-width: 50% !important;
}
/* image effects */
.containerImg .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}
.contentImg {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  cursor: pointer;
}
.contentImg .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.contentImg:hover .content-overlay {
  opacity: 1;
}
.content-image {
  width: 100%;
}
.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.contentImg:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}
.content-details .card-title {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}
.content-details p {
  color: #fff;
}
.fadeIn-bottom {
  top: 80%;
}
.map-responsive,
.preside-ext-googlemaps-map-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-responsive iframe,
.preside-ext-googlemaps-map-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
form .error {
  color: #ff0000;
}
/* link definitions */
a:link,
a:visited,
a:active {
  color: #4e2f2b;
}
a.buttonlink,
a.buttonlink:visited,
a:buttonlink {
  color: #c0ae87 !important;
}
a.buttonlink:hover {
  text-decoration: none;
  background-color: #c0ae87 !important;
  color: #4e2f2b !important;
}
a.footerlink,
a.footerlink:visited,
a:footerlink {
  color: #4e2f2b;
  text-decoration: none;
}
a.footerlink:hover {
  text-decoration: underline !important;
}
a:hover,
a.reverseLink:hover {
  text-decoration: underline;
}
a.reverseLink,
a.reverseLink:link,
a.reverseLink:visited,
a.reverseLink:active {
  color: #c0ae87;
}
a.bookingBtnLink,
a.bookingBtnLink:link,
a.bookingBtnLink:visited,
a.bookingBtnLink:active {
  color: #fff;
}
a.bookingBtnLink:hover {
  text-decoration: none !important;
  color: #fff;
}
a.catTitleHomeLink,
a.catTitleHomeLink:link,
a.catTitleHomeLink:visited,
a.catTitleHomeLink:active,
.catTitleHome {
  font-family: CervoNeue-BoldNeue !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-size: 3.2rem !important;
  color: #ecede9 !important;
}
a.mediumTitleLink,
a.mediumTitleLink:link,
a.mediumTitleLink:visited,
a.mediumTitleLink:active {
  font-family: CervoNeue-BoldNeue !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-size: 1.8rem !important;
  color: #5a5b5d !important;
}
.btn-primary {
  background-color: #4e2f2b !important;
  color: #c0ae87 !important;
  border-color: transparent !important;
}
.btn-primary:hover {
  background-color: #c0ae87 !important;
  color: #4e2f2b !important;
  border-color: transparent !important;
}
.btn-book {
  background-color: #f7a941;
  color: #fff;
}
.txt-acker-back {
  color: #ecede9 !important;
}
a.nav-link,
a.nav-link:link,
a.nav-link:visited,
a.nav-link:active {
  color: #5E5244 !important;
  font-family: CervoNeue-BoldNeue !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-size: 28px !important;
  padding: 0;
  text-decoration: none!important;
  text-align: left;
}
a.nav-link:hover,
a.sm-link:hover {
  color: #c0ae87 !important;
  text-decoration: underline !important;
}
a.nav-link::after {
  content: none !important;
}
/*
 * Navbar
 */
#mainNav.navbar {
  padding: 0.5rem 0 0 0;
  border-radius: 0 !important;
  background-color: #ecede9;
}
.navbar-toggler .navbar-toggler-icon {
  background-image: url("/assets/img/cancel.svg");
  width: 40px;
  height: 40px;
}
.navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(90, 91, 93, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  width: 40px;
  height: 40px;
}
.navbar-toggler {
  height: 65px;
  border: 0;
  margin-left: 10px;
}
.navbar-collapse.collapse.show {
  background-color: #A9A793;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
/* specific home definitions */
#home-left {
  background-image: url(/assets/img/acker-dna.png);
  background-size: cover;
  min-height: 600px;
}
#home-right {
  background-size: cover;
  min-height: 600px;
  cursor: pointer;
}
.homeMainImg {
  max-height: 450px;
}
.container {
  max-width: 960px;
}
/*
 * Custom translucent site header
 */
.site-header {
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.site-header a {
  color: #ecede9;
  transition: ease-in-out color 0.15s;
}
.site-header a:hover {
  text-decoration: underline;
}
.site-footer a {
  color: #5a5b5d;
  transition: ease-in-out color 0.15s;
}
.site-footer a:hover {
  text-decoration: underline;
}
.footerDivider {
  border-top: 3px solid #4e2f2b;
  width: 10%;
  height: 10px;
}
/*
 * Dummy devices (replace them with your own or something else entirely!)
 */
.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}
/*
 * Zimmer buchen
 */
.btn-zimmer-buchen {
  color: #ecede9 !important;
  font-family: "GeogrotesqueSlab-Rg";
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase !important;
  background-color: #524A3F;
  border-radius: 0;
}
.btn-zimmer-buchen:hover {
  color: #c0ae87 !important;
}
.zimmer-buchen-wrapper {
  margin-top: 20px;
  padding: 20px 10px;
  border: 2px solid #524A3F;
}
.form-control {
  border-radius: 0;
}
/*
 * Language Selektor
 */
.languageSelector {
  margin-bottom: 20px !important;
}
.languageSelector li {
  margin: 10px 25px 0 25px;
}
.btn-language {
  height: 45px;
  color: #ecede9;
  font-family: "GeogrotesqueSlab-Rg";
  font-size: 18px;
  line-height: 27px !important;
  text-align: center;
  background-color: transparent;
  border-radius: 0;
  border: 2px solid #524A3F;
  color: #524A3F;
}
.btn-language:hover {
  color: #c0ae87 !important;
}
.btn-language.currentLang {
  height: 45px;
  color: #ecede9;
  font-family: "GeogrotesqueSlab-Rg";
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  background-color: #524A3F;
  border-radius: 0;
}
.btn-language.currentLang:hover {
  color: #c0ae87 !important;
}
.formbuilder-form form .btn {
  width: 100%;
  color: #ecede9 !important;
  font-family: "GeogrotesqueSlab-Rg";
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase !important;
  background-color: #524A3F;
  border-radius: 0;
}
.formbuilder-form form .btn:hover {
  color: #c0ae87 !important;
}
/* 
 * Quadratische Textblöcke
 */
.squared-box {
  position: relative;
  padding-top: 100%;
  width: 100%;
}
.squared-box-halfHeight {
  padding-top: 50%;
}
.squared-box:before {
  content: "";
  display: block;
}
.squared-box .inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  padding: 25px;
}
/*
 * Extra utilities
 */
.owl-carousel {
  position: relative;
}
.owl-prev,
.owl-next {
  position: absolute;
  top: 0;
  height: 100%;
  margin-top: -10px;
  color: #a3a4a3 !important;
}
button.owl-prev:focus,
button.owl-next:focus {
  outline: none;
}
.owl-prev {
  left: 5rem;
}
.owl-next {
  right: 5rem;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: none;
  color: #4c2c2b !important;
  text-decoration: none;
}
.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}
.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
.flex-equal > * {
  -ms-flex: 1;
  -webkit-box-flex: 1;
  flex: 1;
}
#RoomAccordion {
  width: 100%;
}
.roomCard {
  border-bottom: 2px solid #4D2E2B;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.roomCard > div > a {
  cursor: pointer;
}
.boxBorderDouble {
  border-top: 2px solid #4D2E2B;
  border-bottom: 2px solid #4D2E2B;
}
.bookingCTA {
  background-color: #f7a941;
  color: #fff;
  cursor: pointer;
  border: 2px solid #f7a941;
}
.bookingCTA:hover {
  background-color: #d29137;
  color: #fff;
  border: 2px solid #fff;
  transition: all 0.8s ease;
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
  }
  .color-acker-grey {
    background-color: #A9A793;
    padding-left: 1.5rem;
  }
  body {
    margin-top: 120px;
  }
  .languageSelector li {
    margin: 10px 5px 0 5px;
  }
  .nav-item.pr-4 {
    padding-right: 0 !important;
  }
  ul.navbar-nav > li.active > a {
    color: #c0ae87 !important;
  }
}
@media (max-width: 768px) {
  body {
    margin-top: 80px;
  }
  .regTxt {
    font-size: 1rem;
  }
  .biggerTxt {
    font-size: 1.2rem;
  }
  a.catTitleHomeLink,
  a.catTitleHomeLink:link,
  a.catTitleHomeLink:visited,
  a.catTitleHomeLink:active,
  .catTitleHome {
    font-size: 2.2rem !important;
  }
  .color-acker-grey {
    background-color: #ecede9;
  }
  #navbarCollapse {
    margin-top: 15px;
    background-color: #ecede9;
  }
  .nav-fill .nav-item {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: left!important;
    margin-left: 25px;
  }
  #mainNav.navbar {
    background-color: #fff !important;
    padding-bottom: 15px;
  }
  .modal-dialog {
    max-width: 98% !important;
  }
  .owl-prev,
  .owl-next {
    font-size: 0.5rem !important;
    color: #fff !important;
  }
  .owl-prev {
    left: 0.1rem;
  }
  .owl-next {
    right: 0.1rem;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .languageSelector li {
    margin: 10px 15px 0 0;
  }
  .nav-item.pr-4 {
    padding-right: 1.5rem !important;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1280px) {
  .container {
    max-width: 1300px;
  }
}
.overflow-hidden {
  overflow: hidden;
}
.dropdown-menu {
  background-color: transparent;
  border: none;
}
.dropdown-menu li.nav-item a.nav-link {
  text-decoration: none !important;
  font-family: GeogrotesqueSlab-Rg !important;
  font-weight: lighter!important;
  text-align: left;
  font-size: 1em !important;
}
.dropdown-menu li.nav-item a.nav-link:hover {
  text-decoration: underline !important;
}
.dropdown:hover > .dropdown-menu {
  display: block;
}
.dropdown > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}
.btnIcon {
  font-style: normal;
}
.btnStyleSolid {
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-darkbrown {
  border: 2px solid #4e2f2b;
  background-color: #4e2f2b;
  color: #fff;
  color: #c0ae87 !important;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-darkbrown:hover {
  color: #4e2f2b;
  background-color: transparent;
  border: 2px solid #4e2f2b;
}
.btn-outline-darkbrown {
  border: 2px solid #4e2f2b;
  color: #4e2f2b;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-darkbrown:hover {
  background-color: #4e2f2b;
  color: #fff;
  border: 2px solid #4e2f2b;
}
.modal-darkbrown {
  border: 2px solid #4e2f2b;
  background-color: #4e2f2b;
  color: #fff;
}
.bg-darkbrown {
  background-color: #4e2f2b !important;
}
.text-color-darkbrown {
  color: #4e2f2b !important;
}
.btn-grau {
  border: 2px solid #5a5b5d;
  background-color: #5a5b5d;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-grau:hover {
  color: #5a5b5d;
  background-color: transparent;
  border: 2px solid #5a5b5d;
}
.btn-outline-grau {
  border: 2px solid #5a5b5d;
  color: #5a5b5d;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-grau:hover {
  background-color: #5a5b5d;
  color: #fff;
  border: 2px solid #5a5b5d;
}
.modal-grau {
  border: 2px solid #5a5b5d;
  background-color: #5a5b5d;
  color: #fff;
}
.bg-grau {
  background-color: #5a5b5d !important;
}
.text-color-grau {
  color: #5a5b5d !important;
}
.btn-gruen {
  border: 2px solid #728143;
  background-color: #728143;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-gruen:hover {
  color: #728143;
  background-color: transparent;
  border: 2px solid #728143;
}
.btn-outline-gruen {
  border: 2px solid #728143;
  color: #728143;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-gruen:hover {
  background-color: #728143;
  color: #fff;
  border: 2px solid #728143;
}
.modal-gruen {
  border: 2px solid #728143;
  background-color: #728143;
  color: #fff;
}
.bg-gruen {
  background-color: #728143 !important;
}
.text-color-gruen {
  color: #728143 !important;
}
.btn-gold {
  border: 2px solid #c0ae87;
  background-color: #c0ae87;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-gold:hover {
  color: #c0ae87;
  background-color: transparent;
  border: 2px solid #c0ae87;
}
.btn-outline-gold {
  border: 2px solid #c0ae87;
  color: #c0ae87;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-gold:hover {
  background-color: #c0ae87;
  color: #fff;
  border: 2px solid #c0ae87;
}
.modal-gold {
  border: 2px solid #c0ae87;
  background-color: #c0ae87;
  color: #fff;
}
.bg-gold {
  background-color: #c0ae87 !important;
}
.text-color-gold {
  color: #c0ae87 !important;
}
.btn-toepfer {
  border: 2px solid #5b4f40;
  background-color: #5b4f40;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-toepfer:hover {
  color: #5b4f40;
  background-color: transparent;
  border: 2px solid #5b4f40;
}
.btn-outline-toepfer {
  border: 2px solid #5b4f40;
  color: #5b4f40;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-toepfer:hover {
  background-color: #5b4f40;
  color: #fff;
  border: 2px solid #5b4f40;
}
.modal-toepfer {
  border: 2px solid #5b4f40;
  background-color: #5b4f40;
  color: #fff;
}
.bg-toepfer {
  background-color: #5b4f40 !important;
}
.text-color-toepfer {
  color: #5b4f40 !important;
}
.btn-weber {
  border: 2px solid #c2c0bd;
  background-color: #c2c0bd;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-weber:hover {
  color: #c2c0bd;
  background-color: transparent;
  border: 2px solid #c2c0bd;
}
.btn-outline-weber {
  border: 2px solid #c2c0bd;
  color: #c2c0bd;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-weber:hover {
  background-color: #c2c0bd;
  color: #fff;
  border: 2px solid #c2c0bd;
}
.modal-weber {
  border: 2px solid #c2c0bd;
  background-color: #c2c0bd;
  color: #fff;
}
.bg-weber {
  background-color: #c2c0bd !important;
}
.text-color-weber {
  color: #c2c0bd !important;
}
.btn-gerber {
  border: 2px solid #4c2c2b;
  background-color: #4c2c2b;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-gerber:hover {
  color: #4c2c2b;
  background-color: transparent;
  border: 2px solid #4c2c2b;
}
.btn-outline-gerber {
  border: 2px solid #4c2c2b;
  color: #4c2c2b;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-gerber:hover {
  background-color: #4c2c2b;
  color: #fff;
  border: 2px solid #4c2c2b;
}
.modal-gerber {
  border: 2px solid #4c2c2b;
  background-color: #4c2c2b;
  color: #fff;
}
.bg-gerber {
  background-color: #4c2c2b !important;
}
.text-color-gerber {
  color: #4c2c2b !important;
}
.btn-papierer {
  border: 2px solid #aaa891;
  background-color: #aaa891;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-papierer:hover {
  color: #aaa891;
  background-color: transparent;
  border: 2px solid #aaa891;
}
.btn-outline-papierer {
  border: 2px solid #aaa891;
  color: #aaa891;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-papierer:hover {
  background-color: #aaa891;
  color: #fff;
  border: 2px solid #aaa891;
}
.modal-papierer {
  border: 2px solid #aaa891;
  background-color: #aaa891;
  color: #fff;
}
.bg-papierer {
  background-color: #aaa891 !important;
}
.text-color-papierer {
  color: #aaa891 !important;
}
.btn-ackerback {
  border: 2px solid #ecede9;
  background-color: #ecede9;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-ackerback:hover {
  color: #ecede9;
  background-color: transparent;
  border: 2px solid #ecede9;
}
.btn-outline-ackerback {
  border: 2px solid #ecede9;
  color: #ecede9;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-ackerback:hover {
  background-color: #ecede9;
  color: #fff;
  border: 2px solid #ecede9;
}
.modal-ackerback {
  border: 2px solid #ecede9;
  background-color: #ecede9;
  color: #fff;
}
.bg-ackerback {
  background-color: #ecede9 !important;
}
.text-color-ackerback {
  color: #ecede9 !important;
}
.btn-ackerback2 {
  border: 2px solid #d1cec5;
  background-color: #d1cec5;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-ackerback2:hover {
  color: #d1cec5;
  background-color: transparent;
  border: 2px solid #d1cec5;
}
.btn-outline-ackerback2 {
  border: 2px solid #d1cec5;
  color: #d1cec5;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-ackerback2:hover {
  background-color: #d1cec5;
  color: #fff;
  border: 2px solid #d1cec5;
}
.modal-ackerback2 {
  border: 2px solid #d1cec5;
  background-color: #d1cec5;
  color: #fff;
}
.bg-ackerback2 {
  background-color: #d1cec5 !important;
}
.text-color-ackerback2 {
  color: #d1cec5 !important;
}
.btn-white {
  border: 2px solid #fff;
  background-color: #fff;
  color: #000;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-white:hover {
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
}
.btn-outline-white {
  border: 2px solid #fff;
  color: #fff;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-white:hover {
  background-color: #fff;
  color: #000;
  border: 2px solid #fff;
}
.modal-white {
  border: 2px solid #fff;
  background-color: #fff;
  color: #fff;
}
.bg-white {
  background-color: #fff !important;
}
.text-color-white {
  color: #fff !important;
}
.btn-black {
  border: 2px solid #000;
  background-color: #000;
  color: #fff;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-black:hover {
  color: #000;
  background-color: transparent;
  border: 2px solid #000;
}
.btn-outline-black {
  border: 2px solid #000;
  color: #000;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  position: relative;
  z-index: 1;
  border-radius: 4px;
}
.btn-outline-black:hover {
  background-color: #000;
  color: #fff;
  border: 2px solid #000;
}
.modal-black {
  border: 2px solid #000;
  background-color: #000;
  color: #fff;
}
.bg-black {
  background-color: #000 !important;
}
.text-color-black {
  color: #000 !important;
}
